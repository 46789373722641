import { useEffect, useState } from "react";

export function Popup({children, show}) {
    function handleEndTransition() {
      if (!show && isVisible) {
        setIsVisible(false);
      }
    }
  
    const [isVisible, setIsVisible] = useState(show);
    const [bgColor, setBgColor] = useState("rgba(0, 0, 0, 0)");
  
    useEffect(() => {
      if (show) {
        if (isVisible) {
          setTimeout(()=>{
            setBgColor("rgba(0, 0, 0, 0.4)");
          }, 50);
        } else {
          setIsVisible(true);
        }
      } else {
        setBgColor("rgba(0, 0, 0, 0)");
      }
    }, [show, isVisible]);
  
    const style = {backgroundColor:bgColor};
  
    return <>
      {isVisible && <div className="popupBackground" style={style} onTransitionEnd={handleEndTransition}> 
        {show && <div className="popupWindow">
          {children}
        </div>}
      </div>}
    </>
  }