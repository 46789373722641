import { useEffect, useState } from "react";
import { FileData, FileUpdateData } from "common/models";
import {getApiPath} from "common/functions"
import { singleFetch } from "common/singleFetch";

/**
 * @param {Object} props
 * @param {string} props.userId
 * @param {FileData} props.fileData
 * @param {Function} props.onFileChanged
 * @param {Function} props.onFileDeleted
 * @param {Boolean} props.show
 * @param {Function} props.close
 */
export function FileEditMobil({userId, fileData, onFileChanged, onFileDeleted}) {
    function getTimeUnit(deleteAt) {
        if (deleteAt === 0) {
            return "disabled"
        }

        const currentTime = Date.now() / 1000;
        const deleteAfterSeconds = deleteAt - currentTime;
        if (deleteAfterSeconds < 2 * 60 * 60) {
            return "minutes";
        } else if (deleteAfterSeconds < 72 * 60 * 60) {
            return "hours";
        } else {
            return "days";
        }
    }

    function getTimeValue(deleteAt, timeUnit) {
        if (timeUnit === "disabled") {
            return 0;
        }

        const currentTime = Date.now() / 1000;
        const deleteAfterSeconds = deleteAt - currentTime;
        switch(timeUnit) {
            case "days" : return Math.floor(deleteAfterSeconds / (24 * 60 * 60)) + 1;
            case "hours": return Math.floor(deleteAfterSeconds / (60 * 60)) + 1;
            default : return Math.floor(deleteAfterSeconds / 60) + 1;
        }
    }

    function isValidForUpdate() {
        const currentTimeUnit = getTimeUnit(fileData.deleteAt);
        const currentDeleteTimeValue = getTimeValue(fileData.deleteAt, currentTimeUnit);

        return fileData.fileName !== newFileName || fileData.deleteDownloads !== newDeleteDownloads 
                || currentTimeUnit !== deleteAfterTimeUnit || currentDeleteTimeValue !== deleteAfterValue;
    }

    function updateFileData() {
        const url = `${getApiPath()}changeFileData/${userId}/${fileData.id}`;

        const updateData = new FileUpdateData();
        updateData.FileName = newFileName;
        updateData.DeleteDownloads = newDeleteDownloads;
        updateData.DeleteAt = getDeleteAt();
        updateData.adjustData(fileData);
        const requestBody = JSON.stringify(updateData);

        singleFetch(url, {method:"POST", body:requestBody, headers:{"Content-Type": "application/json"}})
            .then((response) => {
                if (response.fileUpdated) {
                    onFileChanged();
                }
            });
    }

    function getDeleteAt() {
        if (deleteAfterTimeUnit === "disabled") {
            return 0;
        }

        let deleteAfter;
        switch(deleteAfterTimeUnit) {
            case "days" : deleteAfter = deleteAfterValue * 24 * 60 * 60; 
            break;
            case "hours": deleteAfter = deleteAfterValue * 60 * 60; 
            break;
            default : deleteAfter = deleteAfterValue * 60;
        }
        return Math.floor(Date.now() / 1000) + deleteAfter;
    }

    function deleteFile() {
        const url = `${getApiPath()}deleteFile/${userId}/${fileData.id}`;
        singleFetch(url, {method:"POST"})
            .then((response) => {
                if (response.fileDeleted) {
                    onFileDeleted();
                }
            });
    }

    const[newFileName, setNewFileName] = useState(fileData.fileName);
    const[newDeleteDownloads, setDeleteDownloads] = useState(fileData.deleteDownloads);
    const[deleteAfterValue, setDeleteAfterValue] = useState(0);
    const[deleteAfterTimeUnit, setDeleteAfterTimeUnit] = useState("disabled");

    useEffect(() => {
        const initialTimeUnit = getTimeUnit(fileData.deleteAt);
        const initialTimeValue = getTimeValue(fileData.deleteAt, initialTimeUnit);
        setDeleteAfterValue(initialTimeValue);
        setDeleteAfterTimeUnit(initialTimeUnit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (deleteAfterTimeUnit === "disabled") {
            if (deleteAfterValue !== 0) {
                setDeleteAfterValue(0);
            }
            return;
        }

        if (deleteAfterValue > 120) {
            setDeleteAfterValue(120);
        } else if (deleteAfterValue < 1) {
            setDeleteAfterValue(1);
        }
    }, [deleteAfterValue, deleteAfterTimeUnit]);

    return<div style={{marginTop:"10pt", textAlign:"left"}} >       
        <label>File Name</label>
        <input className="form-control form-control-sm" style={{width:"15em"}} type="text" value={newFileName} onChange={(e) => {setNewFileName(e.target.value)}}/>

        <label style={{marginTop:"10pt"}}>Delete after (X) Downloads</label>
        <input className="form-control form-control-sm" type="number" min="0" value={newDeleteDownloads+""} onChange={(e) => {setDeleteDownloads(+e.target.value)}}/>

        <label style={{display:"block", marginTop:"10pt"}}>Delete After</label>
        <input className="form-control form-control-sm" style={{width:"4rem", display:"inline"}} type="number" min="1" max="120" disabled={deleteAfterTimeUnit === "disabled"}
                value={deleteAfterValue} onChange={(e) => setDeleteAfterValue(+e.target.value)}/>
        <select className="form-select form-select-sm" style={{display:"inline", marginLeft:"5pt", width:"7em"}} value={deleteAfterTimeUnit} onChange={(e) => {setDeleteAfterTimeUnit(e.target.value)}}>
            <option>disabled</option>
            <option>minutes</option>
            <option>hours</option>
            <option>days</option>
        </select>

        <div style={{marginTop:"10pt", textAlign:"center"}}>
            <button className="btn btn-outline-primary" style={{padding:"3pt"}} disabled={!isValidForUpdate()} onClick={updateFileData}>Update</button>
            <button className="btn btn-outline-danger" style={{marginLeft:"5pt", padding:"3pt"}} onClick={deleteFile}>Delete</button>
        </div>

        <table style={{marginTop:"10pt", borderTop:"1pt solid var(--bs-primary)", borderBottom:"1pt solid var(--bs-primary)"}}>
            <tbody>
                <tr>
                    <td>Total Downloas</td>
                    <td style={{paddingLeft:"10pt"}}>{fileData.downloads}</td>
                </tr>
                <tr>
                    <td>Last Activity</td>
                    <td style={{paddingLeft:"10pt"}}>{fileData.getFormatedLastActive()}</td>
                </tr>
                <tr>
                    <td>File Size</td>
                    <td style={{paddingLeft:"10pt"}}>{fileData.getFormatedSize()}</td>
                </tr>
            </tbody>
        </table>

        <div style={{marginTop:"10pt"}}>
            Download URL
            <a style={{display:"block"}}href={fileData.getDownloadUrl()}>{fileData.getDownloadUrl()}</a>
        </div>
    </div>
}

