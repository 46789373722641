import { isMobile } from "react-device-detect";
import { AppMobile } from "./mobile/AppMobile";
import { AppDesktop } from "./desktop/AppDesktop";

export function App() {
  if (isMobile) {
    return <AppMobile/>
  } else {
    return <AppDesktop/>
    //return <AppMobile/>
  }
}