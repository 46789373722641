import { UserControl } from "components/UserControl";
import { useState } from "react";
import { FileListMobil } from "components/mobil/FileListMobil";
import { AvailableServerStorage } from "components/AvailableServerStorage";
import { FileUploadControlMobil } from "components/mobil/FileUploadControlMobil";
import { Popup } from "components/Popup";
import { CloseSvg } from "components/CloseSvg";

const SHOW_POPUP = "show_Attention_Popup";

export function MainMobile() {
  function onUploadFinished() {
    fileListRefreshRef.refresh();
    availableStorageRefreshRef.refresh();
  }

  function onFileDeleted() {
    availableStorageRefreshRef.refresh();
  }

  function onDoNotShowPopup(checked) {
    if (checked) {
      localStorage.setItem(SHOW_POPUP, "false");
    } else {
      localStorage.removeItem(SHOW_POPUP);
    }
  }

  let showPopupDefault = localStorage.getItem(SHOW_POPUP);
  if (showPopupDefault !== "false") {
    showPopupDefault = true;
  } else {
    showPopupDefault = false;
  }
  
  const fileListRefreshRef = {refresh : null};
  const availableStorageRefreshRef = {refresh : null};
  const [userId, setUserId] = useState(null);
  const [showPopup, setShowPopup] = useState(showPopupDefault);
  return <div>
    <div style={{margin:"10pt"}}>
      <UserControl userId={userId} setUserId = {setUserId}/>
      <div style={{height:"1pt", margin:"10pt 0 5pt 0", backgroundColor:"var(--bs-primary)"}}/>
      {userId && <FileUploadControlMobil userId = {userId} onUploadFinished={onUploadFinished}/>}
      <div style={{height:"5pt"}}/>
      {userId && <AvailableServerStorage refreshRef={availableStorageRefreshRef}/>}
      <div style={{height:"1pt", margin:"10pt 0 5pt 0", backgroundColor:"var(--bs-primary)"}}/>
      {userId && <FileListMobil userId={userId} refreshRef={fileListRefreshRef} onFileDeleted={onFileDeleted}/>}
    </div>

    {userId && <Popup show={showPopup}>
      <div style={{height:"20pt", width:"20pt", position:"absolute", right:"2pt", top:"2pt"}} onClick={()=>{setShowPopup(false)}}>
        <CloseSvg/>
      </div>
      <div>
        Developed by a Smog Technician who works at&nbsp;
        <img src="/img/SmogBustersLogo.png" alt="" style={{height:"20pt"}}/><br/>Las Vegas, NV
      </div>
      <div style={{height:"1pt", margin:"5pt 0 5pt 0", backgroundColor:"var(--bs-primary)"}}/>
      <div style={{textAlign:"center"}}>
        <h4 style={{marginBlockStart:"1pt", marginBlockEnd:"1pt", color:"var(--bs-gray-600)"}}>Attention!!!</h4>
        This website was <b>not developed</b> with a focus on security, so please be mindful of the content you upload.<br/> 
        If you choose to upload files containing sensitive information, ensure they are deleted as soon as they are no longer needed.<br/>
        To manage this, you can use the settings:<br/> 
        "Delete After (x) Downloads" and "Delete After."
      </div>
      <div className="form-check" style={{display:"inline-block", marginTop:"10pt"}}>
        <input className="form-check-input" style={{marginRight:"5pt"}} type="checkbox" onChange={(e) => {onDoNotShowPopup(e.target.checked)}}/>
          Don't show this again
      </div>
    </Popup>}
  </div>
}
