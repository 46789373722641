import { FileData } from "common/models";

/**
 * @param {Object} props
 * @param {FileData} props.fileData
 * @param {(fileData:FileData) => void} props.onFileSelected
 */
export function FileItemMobil({fileData, onFileSelected}) {
    return <tr style={{verticalAlign:"middle"}}>
        <td style={{overflow:"hidden"}} onClick={()=> onFileSelected(fileData)}>{fileData.fileName}</td>
        <td className="leftBorder" style={{overflow:"hidden"}} onClick={()=> onFileSelected(fileData)}>{fileData.getFormatedSize()}</td>
        <td className="leftBorder">
            <a style={{display:"block", textAlign:"left", direction:"rtl", textOverflow:"ellipsis", overflow:"hidden"}} href={fileData.getDownloadUrl()}>{fileData.getDownloadUrl()}</a>
        </td>
    </tr>;
}