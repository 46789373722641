import { format } from "date-fns";
import { formatDataSize } from "common/functions";

export class FileData {
    /**@type{string} */
    id;
    /**@type{string} */
    fileName;
    /**@type{number} */
    size;  
    /**@type{number} */
    lastActive;
    /**@type{number} */
    downloads;
    /**@type{number} */
    deleteDownloads;
    /**@type{number} */
    deleteAt;

    constructor(data) {
        this.id = data.id;
        this.fileName = data.fileName;
        this.size = data.size;
        this.lastActive = data.lastActive;
        this.downloads = data.downloads;
        this.deleteDownloads = data.deleteDownloads;
        this.deleteAt = data.deleteAt;
    }

    getDownloadUrl() {
        return `${window.location.origin}/download/${this.id}`;    
    }

    getFormatedLastActive() {
        const lastActiveDate = new Date(this.lastActive * 1000);
        return format(lastActiveDate, "MM/dd/yy hh:mm a");
    }

    getFormatedSize() {
        return formatDataSize(this.size);
    }
}

export class FileUpdateData {
    FileName;
    DeleteAt;  
    DeleteDownloads;

    /**@param{FileData} fileData */
    adjustData(fileData) {
        if (this.DeleteDownloads !== 0) {
            if (this.DeleteDownloads <= fileData.downloads) {
                this.DeleteDownloads = fileData.downloads + 1
            }
        }
    }
}