import { singleFetch } from "common/singleFetch";
import { useEffect, useState } from "react"
import { FileData } from "common/models";
import { FileItemDesktop } from "components/desktop/FileItemDesktop";
import { getApiPath } from "common/functions";

export function FileListDesktop({userId, refreshRef, onFileDeleted}) {

    function getFiles() {
        const url = `${getApiPath()}getFiles/${userId}`;
        singleFetch(url).then((response) => {
            const files = response.map((fileObject) => {return new FileData(fileObject)});
            setFiles(files);
        });
    }

    function refresh() {
        setFiles(null);
    }

    function onFileDeleted2() {
        refresh();
        onFileDeleted();
    }

    const [files, setFiles] = useState(/**@type{Array<FileData>}*/(null));
    refreshRef.refresh = refresh;
    
    useEffect(() => {
        if (files === null) {
            getFiles();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    const fileList = [];
    if (files != null) {
        files.forEach((file) =>{
            const fileItem = <FileItemDesktop userId={userId} fileData={file} onFileChanged={refresh} onFileDeleted={onFileDeleted2} key={file.id}/>;
            fileList.push(fileItem);
        });
    }

    return <div>
        {fileList.length !== 0 && <FileTable fileList={fileList}/>}
        {fileList.length === 0 && <h4 style={{color:"var(--bs-blue)", textAlign:"center"}}>No Files Uploaded</h4>}
    </div> 
}

function FileTable({fileList}) {
    return <table className="table table-light table-striped table-sm" style={{width:"100%"}}>
    <thead>
        <tr style={{verticalAlign:"bottom", textAlign:"left", fontWeight:"500"}}>
            <th>File Name</th>
            <th style={{whiteSpace:"pre"}}>Delete After<br/>       (x)<br/>Downloads</th>
            <th>Delete After</th>
            <th>Total<br/>Downloads</th>
            <th>Last Activity</th>
            <th>File Size</th>
            <th>Download Url</th>
            <th/>
        </tr>
    </thead>
    <tbody className="table-group-divider">
        {fileList}
    </tbody>
</table>
}