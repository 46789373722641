import {BrowserRouter, Route, Routes } from "react-router-dom";
import { Download } from "components/pages/Download";
import { Version } from "components/pages/Version";
import { MainMobile } from "components/pages/mobile/MainMobile";

export function AppMobile() {
    return <div style={{margin:"0pt", display:"flex", flexDirection:"column", height:"100vh"}}>
    <div className="gradient_blue_background" style={{padding:"15pt", color:"var(--bs-primary-bg-subtle)", textAlign:"center"}}>
        <h3 style={{marginBlockStart:"0", marginBlockEnd:"0"}}><i>Easy Share</i></h3>
        <h6 style={{marginLeft:"15pt", marginBlockStart:"0", marginBlockEnd:"3pt"}}>Share your files the easiest way possible with anyone who has a browser.</h6>
    </div>
    <BrowserRouter>
      <Routes>
        <Route path="/:UserId?" element={<MainMobile/>}/>
        <Route path="/download/:FileId" element={<Download/>}/>
        <Route path="/version/frontend" element={<Version/>}/>
      </Routes>
    </BrowserRouter>
  </div>
}