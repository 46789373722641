import { singleFetch } from "common/singleFetch";
import { useEffect, useState } from "react"
import { FileData } from "common/models";
import { FileItemMobil } from "components/mobil/FileItemMobil";
import { getApiPath } from "common/functions";
import { FileEditMobil } from "./FileEditMobil";
import { Popup } from "components/Popup";
import { CloseSvg } from "components/CloseSvg";

export function FileListMobil({userId, refreshRef, onFileDeleted}) {

    function getFiles() {
        const url = `${getApiPath()}getFiles/${userId}`;
        singleFetch(url).then((response) => {
            const files = response.map((fileObject) => {return new FileData(fileObject)});
            setFiles(files);
        });
    }

    function refresh() {
        setPopupFile(null);
        setFiles(null);
    }

    function onFileDeleted2() {
        refresh();
        onFileDeleted();
    }

    function closePopup() {
        setPopupFile(null);
    }

    refreshRef.refresh = refresh;
    const [files, setFiles] = useState(/**@type{Array<FileData>}*/(null));
    const [popupFile, setPopupFile] = useState(/**@type{FileData}*/null);
    
    useEffect(() => {
        if (files === null) {
            getFiles();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    const fileList = [];
    if (files != null) {
        files.forEach((file) =>{
            const fileItem = <FileItemMobil key={file.id} fileData={file} onFileSelected={setPopupFile}/>;
            fileList.push(fileItem);
        });
    }

    return <div>
        {fileList.length !== 0 && <FileTable fileList={fileList}/>}
        {fileList.length === 0 && <h4 style={{color:"var(--bs-blue)", textAlign:"center"}}>No Files Uploaded</h4>}

        <Popup show={popupFile !== null}>
            <div style={{height:"20pt", width:"20pt", position:"absolute", right:"2pt", top:"2pt"}} onClick={closePopup}>
                <CloseSvg/>
            </div>
            {popupFile !== null && <FileEditMobil userId={userId} fileData={popupFile} onFileChanged={refresh} onFileDeleted={onFileDeleted2} show={popupFile != null}/>}
        </Popup>
        
    </div> 
}

function FileTable({fileList}) {
    return <table className="table table-light table-striped table-sm" style={{width:"100%", tableLayout:"fixed"}}>
        <thead>
            <tr style={{verticalAlign:"bottom", textAlign:"left", fontWeight:"500"}}>
                <th style={{width:"50%"}}>File Name</th>
                <th style={{width:"5rem"}}>File Size</th>
                <th>Download Url</th>
            </tr>
        </thead>
        <tbody className="table-group-divider">
            {fileList}
        </tbody>
    </table>
}